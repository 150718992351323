import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";

const Scoring = () => (
  <HowToLayout>
    <h2>Scoring</h2>
    <section className="documentation-section">
      <p>
        4BAGR is unique, as we allow the players and/or organizers to update
        their own game/match scores. All standings and match cards are updated
        in real time as the submissions roll in. We have 2 ways to submit scores
        - Scoreboard Submission or Match Scoresheet Submission.
      </p>
      <p>
        As a league organizer, you can update any score for any game/match at
        any time. Players can only update scores for games/matches of the team
        that they are assigned to. The match scoresheet also shows who the last
        person was to update the score so if there's a discrepancy you can
        easily find out who to talk to.
      </p>

      <div className="youtube-vid-wrap">
        <iframe
          width="100%"
          height="100%"
          style={{ position: "absolute" }}
          src="https://www.youtube.com/embed/9_5NQcEMrxo"
          title="Submit Scores Walkthrough video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </section>
    <section className="documentation-section">
      <h3 id="scoreboard-submission">Scoreboard Submission</h3>
      <p>
        You can submit your scores directly from the scoreboard after each game
        is completed:
      </p>
      <ol>
        <li>
          From the scoreboard screen you'll see 'Submit Score' button in the top
          right - click that
        </li>
        <li>Find your current match from the selection menu</li>
        <li>Make sure your scores align with the correct team</li>
        <li>
          Select which game of the match you'd like to submit that score for
        </li>
        <li>
          Submit and that's it! You can return to the scoreboard for your next
          game.
        </li>
      </ol>
      <p>
        Scoreboard submission is currently only available for the current week's
        matches. All other submissions will need to be made manually from the
        match scoresheet option below.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="match-scoresheet-submission">Match Scoresheet Submission</h3>
      <p>
        Manual score submission is possible from the match scoresheet pop up
        modal on either the player upcoming dashboard or the league schedule
        screen:
      </p>
      <ol>
        <li>Click on the match you'd like to update</li>
        <li>Enter your scores for the games you'd like to submit</li>
        <li>Click 'Update' and that's it!</li>
      </ol>
    </section>
  </HowToLayout>
);

export default Scoring;
